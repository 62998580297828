export const BAD_USER_INPUT_SNACKBAR_CONTENT = {
  sk: 'Vo formulári sa nachádzajú chyby!',
  cs: 'Ve formuláři se nacházejí chyby!',
  en: 'The form contains errors!',
} as const;

export const BAD_USER_INPUT_SNACKBAR_TITLE = {
  sk: 'Chyba!',
  cs: 'Chyba!',
  en: 'Error!',
} as const;

export const BUTTON_BACK = {
  sk: 'Späť',
  cs: 'Zpět',
  en: 'Back',
} as const;

export const BUTTON_SAVE = {
  sk: 'Uložiť',
  cs: 'Uložit',
  en: 'Save',
} as const;

export const CLIENT_ERROR_SNACKBAR_CONTENT = {
  sk: 'Neočakávaná chyba vo vašom prehliadači. <br /><br />Nahláste prosím túto chybu administrátorom.<br /><br />Ďakujeme za trpezlivosť a pochopenie :)',
  cs: 'CLIENT_ERROR_SNACKBAR_CONTENT',
  en: 'CLIENT_ERROR_SNACKBAR_CONTENT',
} as const;

export const CLIENT_ERROR_SNACKBAR_TITLE = {
  sk: 'Chyba!',
  cs: 'Chyba!',
  en: 'Error!',
} as const;

export const CLOSE = {
  sk: 'Zavrieť',
  cs: 'Zavřít',
  en: 'Close',
} as const;

export const CONFIRM_DIALOG_CANCEL = {
  sk: 'Zrušiť',
  cs: 'Zrušit',
  en: 'Cancel',
} as const;

export const CONFIRM_DIALOG_OK = {
  sk: 'Potvrdiť',
  cs: 'Potvrdit',
  en: 'Confirm',
} as const;

export const CONFIRM_DIALOG_TITLE = {
  sk: 'Potvrdenie',
  cs: 'Potvrzení',
  en: 'Confirmation',
} as const;

export const CSRF_ERROR_TOAST_DESCRIPTION = {
  sk: 'Platnosť vašeho tokenu vypršala. Prosím obnovte stránku a skúste operáciu znova.',
  cs: 'Platnost vašeho tokenu vypršela. Prosím obnovte stránku a zkuste operaci znovu.',
  en: 'Your token has expired. Please refresh the page and try the operation again.',
} as const;

export const CSRF_ERROR_TOAST_TITLE = {
  sk: 'Neplatný token',
  cs: 'Neplatný token',
  en: 'Invalid token',
} as const;

export const DATE_FORMAT = {
  sk: 'dd.MM.yyyy',
  cs: 'dd.MM.yyyy',
  en: 'dd.MM.yyyy',
} as const;

export const DATETIME_FORMAT = {
  sk: 'dd.MM.yyyy HH:mm',
  cs: 'dd.MM.yyyy HH:mm',
  en: 'dd.MM.yyyy HH:mm',
} as const;

export const DATETIME_PICKER_CANCEL = {
  sk: 'Zrušiť',
  cs: 'Zrušit',
  en: 'Cancel',
} as const;

export const DATETIME_PICKER_CLEAR = {
  sk: 'Zmazať',
  cs: 'Smazat',
  en: 'Clear',
} as const;

export const DATETIME_PICKER_OK = {
  sk: 'Potvrdiť',
  cs: 'Potvrdit',
  en: 'Confirm',
} as const;

export const DATETIME_PICKER_TODAY = {
  sk: 'Dnes',
  cs: 'Dnes',
  en: 'Today',
} as const;

export const DOWNLOAD_BUTTON = {
  sk: 'Prevziať',
  cs: 'Stáhnout',
  en: 'Download',
} as const;

export const DROPZONE_FIELD_UPLOADING = {
  sk: 'Prebieha upload. Počkajte kým sa všetky súbory nahrajú na server.',
  cs: 'Probíhá upload. Počkejte, až se všechny soubory nahrají na server.',
  en: 'Uploading is in progress. Wait until all files are uploaded to the server.',
} as const;

export const FIELD_DATE_IS_INVALID = {
  sk: 'Tento dátum nie je platný',
  cs: 'Toto datum není platné',
  en: 'This date is not valid',
} as const;

export const FIELD_IS_REQUIRED = {
  sk: 'Toto pole je povinné',
  cs: 'Toto pole je povinné',
  en: 'This field is required',
} as const;

export const FIELD_MUST_BE_POSITIVE_NUMBER = {
  sk: 'Toto pole musí byť kladné číslo',
  cs: 'Toto pole musí být kladné číslo',
  en: 'This field must be a positive number',
} as const;

export const FILE_FIELD_REMOVE_TOOLTIP = {
  sk: 'Odstrániť',
  cs: 'Odstranit',
  en: 'Remove',
} as const;

export const FILE_FIELD_UPLOAD_LABEL = {
  sk: 'Nahrať',
  cs: 'Nahrát',
  en: 'Upload',
} as const;

export const FORM_IS_DIRTY_PROMPT = {
  sk: 'Naozaj chcete odísť z tejto stránky? Máte neuložené zmeny.',
  cs: 'Opravdu chcete odejít z této stránky? Máte neuložené změny.',
  en: 'Are you sure you want to leave this page? You have unsaved changes.',
} as const;

export const IMAGE_FIELD_REMOVE_IMAGE_TOOLTIP = {
  sk: 'Odstrániť',
  cs: 'Odstranit',
  en: 'Remove',
} as const;

export const IMAGE_FIELD_UPLOAD_LABEL = {
  sk: 'Nahrať',
  cs: 'Nahrát',
  en: 'Upload',
} as const;

export const LOAD_ERROR_MESSAGE = {
  sk: 'Nepodarilo sa načítať obsah kvôli chybe.',
  cs: 'Nepodařilo se načítat obsah kvůli chybě.',
  en: 'The content is unavailable due to an error. ',
} as const;

export const MESSAGE_DIALOG_CLOSE = {
  sk: 'Zavrieť',
  cs: 'Zavřít',
  en: 'Close',
} as const;

export const MINUTES_SHORTCUT = {
  sk: 'min.',
  cs: 'min.',
  en: 'min.',
} as const;

export const NO = {
  sk: 'nie',
  cs: 'ne',
  en: 'no',
} as const;

export const NOT_AUTHORIZED_TOAST_DESCRIPTION = {
  sk: 'Na túto akciu nemáte oprávnenie.',
  cs: 'Na tuto akci nemáte oprávnění.',
  en: 'You are not authorized to take this action',
} as const;

export const NOT_AUTHORIZED_TOAST_TITLE = {
  sk: 'Neautorizovaná akcia',
  cs: 'Neautorizovaná akce',
  en: 'Unauthorized action',
} as const;

export const NOTHING = {
  sk: 'Nič',
  cs: 'Nic',
  en: 'Nothing',
} as const;

export const REACT_TABLE_COLUMNS_VISIBLITY = {
  sk: 'Nastavenie tabuľky',
  cs: 'Nastavení tabulky',
  en: 'Table settings',
} as const;

export const REACT_TABLE_EXPORT_XLSX = {
  sk: 'XLSX',
  cs: 'XLSX',
  en: 'XLSX',
} as const;

export const REACT_TABLE_LOADING = {
  sk: 'Načítavanie...',
  cs: 'Načítání...',
  en: 'Loading...',
} as const;

export const REACT_TABLE_NEXT = {
  sk: 'Nasledujúca',
  cs: 'Následující',
  en: 'Next',
} as const;

export const REACT_TABLE_NO_RESULTS = {
  sk: 'Nenašli sa žiadne vyhovujúce záznamy.',
  cs: 'Nebyly nalezeny žádné vyhovující záznamy.',
  en: 'No matching entries found.',
} as const;

export const REACT_TABLE_PAGE = {
  sk: 'Strana <0></0> z {{ pages }}',
  cs: 'Strana <0></0> z {{ pages }}',
  en: 'Page <0></0> from {{ pages }}',
} as const;

export const REACT_TABLE_PREVIOUS = {
  sk: 'Predchádzajúca',
  cs: 'Předchozí',
  en: 'Previous',
} as const;

export const REACT_TABLE_PRINT = {
  sk: 'Vytlačiť',
  cs: 'Vytisknout',
  en: 'Print',
} as const;

export const REACT_TABLE_RESET_FILTERS = {
  sk: 'Resetovať filtre',
  cs: 'Resetovat filtry',
  en: 'Reset filters',
} as const;

export const REACT_TABLE_ROWS = {
  sk: ['1 záznam', '{{ count }} záznamy', '{{ count }} záznamov'],
  cs: ['1 záznam', '{{ count }} záznamy', '{{ count }} záznamů'],
  en: ['1 entry', '{{ count }} entries'],
} as const;

export const REACT_TABLE_TOTAL_COUNT = {
  sk: 'z {{ count }}',
  cs: 'z {{ count }}',
  en: 'from {{ count }}',
} as const;

export const REACT_TABLE_VISIBILITY_CLOSE = {
  sk: 'Zavrieť',
  cs: 'Zavřít',
  en: 'Close',
} as const;

export const REACT_TABLE_VISIBILITY_HEADER = {
  sk: 'Nastavenie stĺpcov',
  cs: 'Nastavení sloupců',
  en: 'Setting the columns',
} as const;

export const REACT_TABLE_VISIBILITY_TOGGLE_ALL = {
  sk: 'Prepnúť všetky',
  cs: 'Přepnout všechny',
  en: 'Toggle all',
} as const;

export const SERVER_ERROR_APOLOGIES = {
  sk: 'Ospravedlňujeme sa za výpadok',
  cs: 'Omlouváme se za výpadek',
  en: 'We apologize for the outage',
} as const;

export const SERVER_ERROR_MESSAGE = {
  sk: 'V aplikácii nastala chyba, s ktorou sme nerátali.',
  cs: 'V aplikaci nastala chyba, se kterou jsme nepočítali.',
  en: 'There was an unexpected error in the application.',
} as const;

export const SERVER_ERROR_MESSAGE_WITH_REPORT = {
  sk: 'V aplikácii nastala chyba, s ktorou sme nerátali. Ak nám chcete pomôcť, čo najrýchlejšie problém odstrániť, kliknite na tlačítko nižšie a vyplňte dodatočné informácie o tom, ako ste na chybu narazili.',
  cs: 'V aplikaci nastala chyba, se kterou jsme nepočítali. Pokud nám chcete pomoci, co nejrychleji problém odstranit, klepněte na tlačítko níže a vyplňte dodatečné informace o tom, jak jste na chybu narazili.',
  en: 'There was an unexpected error in the application.  If you want to help us fix the problem as quickly as possible, please click the button below and fill in additional information about how you encountered the error.',
} as const;

export const SERVER_ERROR_REPORT = {
  sk: 'Nahlásiť detail chyby',
  cs: 'Nahlásit detail chyby',
  en: 'Report error details',
} as const;

export const SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY = {
  sk: 'Niektoré položky sú neplatné. Prosím skontrolujte ich.',
  cs: 'Některé položky jsou neplatné. Prosím zkontrolujte je.',
  en: 'Some items are invalid. Please check them.',
} as const;

export const SERVER_ERROR_SENTRY_ERROR_GENERIC = {
  sk: 'Nastala neočakávaná chyba pri odosielaní vášho reportu. Skúste to prosím znova.',
  cs: 'Nastala neočekávaná chyba při odesílání vašeho reportu. Zkuste to prosím znovu.',
  en: 'An unexpected error occurred while sending your report. Please try over again.',
} as const;

export const SERVER_ERROR_SENTRY_LABEL_CLOSE = {
  sk: 'Zavrieť',
  cs: 'Zavřít',
  en: 'Close',
} as const;

export const SERVER_ERROR_SENTRY_LABEL_COMMENTS = {
  sk: 'Čo presne sa stalo? Čo ste chceli urobiť?',
  cs: 'Co přesně se stalo? Co jste chtěli udělat?',
  en: 'What exactly happened? What did you want to do?',
} as const;

export const SERVER_ERROR_SENTRY_LABEL_EMAIL = {
  sk: 'E-mail',
  cs: 'E-mail',
  en: 'E-mail',
} as const;

export const SERVER_ERROR_SENTRY_LABEL_NAME = {
  sk: 'Vaše meno',
  cs: 'Vaše jméno',
  en: 'Your name',
} as const;

export const SERVER_ERROR_SENTRY_LABEL_SUBMIT = {
  sk: 'Odoslať',
  cs: 'Odeslat',
  en: 'Send',
} as const;

export const SERVER_ERROR_SENTRY_SUBTITLE = {
  sk: 'Prevádzkovateľ servera bol informovaný',
  cs: 'SEProvozovatel serveru byl informován',
  en: 'The operator of the server has been informed',
} as const;

export const SERVER_ERROR_SENTRY_SUBTITLE2 = {
  sk: 'Ak nám chcete pomôcť, napíšte nám, čo sa stalo.',
  cs: 'Chcete-li nám pomoci, napište nám, co se stalo.',
  en: 'If you want to help us, write to us what happened. ',
} as const;

export const SERVER_ERROR_SENTRY_SUCCESS_MESSAGE = {
  sk: 'Váš report bol úspešne odoslaný. Ďakujeme.',
  cs: 'Váš report byl úspěšně odeslán. Děkujeme.',
  en: 'Your report has been successfully sent. Thank you.',
} as const;

export const SERVER_ERROR_SENTRY_TITLE = {
  sk: 'Vyzerá to, že máme nejaký technický problém.',
  cs: 'Vypadá to, že máme nějaký technický problém.',
  en: 'It looks like we are having some technical problem.',
} as const;

export const SERVER_ERROR_SNACKBAR_CONTENT = {
  sk: 'Počas spracovania nastala chyba na serveri. <br /><br />Nahláste prosím túto chybu administrátorom.<br /><br />Ďakujeme za trpezlivosť a pochopenie :)',
  cs: 'Během zpracování nastala chyba na serveru. <br /><br />Nahlaste prosím tuto chybu administrátorům.<br /><br />Děkujeme za trpělivost a pochopení :)',
  en: 'A server error occurred during processing. <br /><br />Please report this error to the administrator.<br /><br />Thank you for your patience and understanding :)',
} as const;

export const SERVER_ERROR_SNACKBAR_TITLE = {
  sk: 'Chyba!',
  cs: 'Chyba!',
  en: 'Error!',
} as const;

export const SERVER_ERROR_TITLE = {
  sk: 'Neočakávaná chyba',
  cs: 'Neočekávaná chyba',
  en: 'Unexpected error',
} as const;

export const SERVER_ERROR_TOAST_DESCRIPTION = {
  sk: 'Počas spracovania nastala chyba na serveri. <br /><br />Nahláste prosím túto chybu administrátorom.<br /><br />Ďakujeme za trpezlivosť a pochopenie :)',
  cs: 'Během zpracování nastala chyba na serveru. <br /><br />Nahlaste prosím tuto chybu administrátorům.<br /><br />Děkujeme za trpělivost a pochopení :)',
  en: 'A server error occurred during processing. <br /><br />Please report this error to the administrator.<br /><br />Thank you for your patience and understanding :)',
} as const;

export const SERVER_ERROR_TOAST_TITLE = {
  sk: 'Chyba',
  cs: 'Chyba',
  en: 'Error',
} as const;

export const SLATE_EDITOR_BOLD = {
  sk: 'Tučné',
  cs: 'Tučné',
  en: 'Bold',
} as const;

export const SLATE_EDITOR_BULLETED_LIST = {
  sk: 'Zoznam s odrážkami',
  cs: 'Seznam s odrážkami',
  en: 'Bulleted List',
} as const;

export const SLATE_EDITOR_HEADING_FIVE = {
  sk: 'Nadpis 5',
  cs: 'Nadpis 5',
  en: 'Heading 5',
} as const;

export const SLATE_EDITOR_HEADING_FOUR = {
  sk: 'Nadpis 4',
  cs: 'Nadpis 4',
  en: 'Heading 4',
} as const;

export const SLATE_EDITOR_HEADING_ONE = {
  sk: 'Nadpis 1',
  cs: 'Nadpis 1',
  en: 'Heading 1',
} as const;

export const SLATE_EDITOR_HEADING_SIX = {
  sk: 'Nadpis 6',
  cs: 'Nadpis 6',
  en: 'Heading 6',
} as const;

export const SLATE_EDITOR_HEADING_THREE = {
  sk: 'Nadpis 3',
  cs: 'Nadpis 3',
  en: 'Heading 3',
} as const;

export const SLATE_EDITOR_HEADING_TWO = {
  sk: 'Nadpis 2',
  cs: 'Nadpis 2',
  en: 'Heading 2',
} as const;

export const SLATE_EDITOR_ITALIC = {
  sk: 'Kurzíva',
  cs: 'Kurzíva',
  en: 'Italic',
} as const;

export const SLATE_EDITOR_NUMBERED_LIST = {
  sk: 'Číslovaný zoznam',
  cs: 'Číslovaný seznam',
  en: 'Numbered List',
} as const;

export const SLATE_EDITOR_PARAGRAPH = {
  sk: 'Odstavec',
  cs: 'Odstavec',
  en: 'Paragraph',
} as const;

export const SLATE_EDITOR_REDO = {
  sk: 'Znovu',
  cs: 'Znovu',
  en: 'Redo',
} as const;

export const SLATE_EDITOR_UNDERLINE = {
  sk: 'Podčiarknuté',
  cs: 'Podtrženo',
  en: 'Underline',
} as const;

export const SLATE_EDITOR_UNDO = {
  sk: 'Späť',
  cs: 'Zpět',
  en: 'Undo',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_BUTTON_TOOLTIP = {
  sk: 'Vložiť obrázok',
  cs: 'Vložit obrázek',
  en: 'Insert an image',
} as const;

export const SLATE_WITH_FILES_FILE_BUTTON_TOOLTIP = {
  sk: 'Vložiť súbor',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_LINK = {
  sk: 'Na akú URL adresu má klik na obrázok smerovať?',
  cs: 'SLATE_WITH_IMAGES_IMAGE_DIALOG_LINK',
  en: 'SLATE_WITH_IMAGES_IMAGE_DIALOG_LINK',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_OPEN_NEW_WINDOW = {
  sk: 'Otvoriť v novom okne?',
  cs: 'Otevřít v novém okně?',
  en: 'Open in a new window?',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_RESOURCE = {
  sk: 'Obrázok',
  cs: 'Obrázek',
  en: 'Image',
} as const;

export const SLATE_WITH_FILES_FILE_DIALOG_RESOURCE = {
  sk: 'Súbor',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_RESOURCE_BUTTON = {
  sk: 'Nahrať obrázok na server',
  cs: 'Nahrát obrázek na server',
  en: 'Upload image to server',
} as const;

export const SLATE_WITH_FILES_FILE_DIALOG_RESOURCE_BUTTON = {
  sk: 'Nahrať súbor na server',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_SUBMIT_BUTTON = {
  sk: 'Vložiť obrázok',
  cs: 'Vložit obrázek',
  en: 'Insert an image',
} as const;

export const SLATE_WITH_FILES_FILE_DIALOG_SUBMIT_BUTTON = {
  sk: 'Vložiť súbor',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_TITLE = {
  sk: 'Vložiť obrázok',
  cs: 'Vložit obrázek',
  en: 'Insert an image',
} as const;

export const SLATE_WITH_FILES_FILE_DIALOG_TITLE = {
  sk: 'Vložiť súbor',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_URL = {
  sk: 'URL obrázka',
  cs: 'URL obrázku',
  en: 'Image URL',
} as const;

export const SLATE_WITH_IMAGES_IMAGE_DIALOG_URL_IS_INVALID = {
  sk: 'Zadaná URL nie je adresa obrázku',
  cs: 'Zadaná URL není adresa obrázku',
  en: 'The URL entered is not an image address',
} as const;

export const SLATE_WITH_IMAGES_TABLE_BUTTON_TOOLTIP = {
  sk: 'Vložiť tabuľku',
  cs: 'Vložit tabulku',
  en: 'Insert table',
} as const;

export const SLATE_WITH_LINE_BREAKS_BUTTON_TOOLTIP = {
  sk: 'Nový riadok',
  cs: 'Nový řádek',
  en: 'Add new row',
} as const;

export const SLATE_WITH_LINE_BREAKS_SYMBOL_TOOLTIP = {
  sk: 'Nový riadok',
  cs: 'Nový řádek',
  en: 'Add new row',
} as const;

export const SLATE_WITH_LINE_VARIABLES_VARIABLE_BUTTON_TOOLTIP = {
  sk: 'Vložiť premennú',
  cs: 'Vložit proměnnou',
  en: 'Insert variable',
} as const;

export const SLATE_WITH_LINKS_LINK_BUTTON_TOOLTIP = {
  sk: 'Vytvoriť odkaz',
  cs: 'Vytvořit odkaz',
  en: 'Create link',
} as const;

export const SLATE_WITH_LINKS_LINK_DIALOG_OPEN_NEW_WINDOW = {
  sk: 'Otvoriť v novom okne?',
  cs: 'Otevřít v novém okně?',
  en: 'Open in a new window?',
} as const;

export const SLATE_WITH_LINKS_LINK_DIALOG_SUBMIT_BUTTON = {
  sk: 'Vytvoriť odkaz',
  cs: 'Vytvořit odkaz',
  en: 'Create link',
} as const;

export const SLATE_WITH_LINKS_LINK_DIALOG_TEXT = {
  sk: 'Zobrazovaný text',
  cs: 'Zobrazovaný text',
  en: 'Displayed text',
} as const;

export const SLATE_WITH_LINKS_LINK_DIALOG_TITLE = {
  sk: 'Vytvoriť odkaz',
  cs: 'Vytvořit odkaz',
  en: 'Create link',
} as const;

export const SLATE_WITH_LINKS_LINK_DIALOG_URL = {
  sk: 'Na akú URL adresu má tento odkaz smerovať?',
  cs: 'Na jakou URL adresu má tento odkaz směřovat?',
  en: 'What URL should this link point to?',
} as const;

export const SLATE_WITH_LINKS_LINK_DIALOG_URL_IS_INVALID = {
  sk: 'Zadaná adresa nie je URL adresa',
  cs: 'Zadaná adresa není URL adresa',
  en: 'The address entered is not a URL',
} as const;

export const SLATE_WITH_LINKS_UNLINK_BUTTON_TOOLTIP = {
  sk: 'Zrušiť odkaz',
  cs: 'Zrušit odkaz',
  en: 'Unlink',
} as const;

export const SLATE_WITH_TABLES_EDITOR_TABLE_ADD_COLUMN = {
  sk: 'Pridať stĺpec',
  cs: 'Přidat sloupec',
  en: 'Add column',
} as const;

export const SLATE_WITH_TABLES_EDITOR_TABLE_ADD_ROW = {
  sk: 'Pridať riadok',
  cs: 'Přidat řádek',
  en: 'Add row',
} as const;

export const SLATE_WITH_TABLES_EDITOR_TABLE_DELETE_COLUMN = {
  sk: 'Odobrať stĺpec',
  cs: 'Odebrat sloupec',
  en: 'Remove column',
} as const;

export const SLATE_WITH_TABLES_EDITOR_TABLE_DELETE_ROW = {
  sk: 'Odobrať riadok',
  cs: 'Odebrat řádek',
  en: 'Remove row',
} as const;

export const SLATE_WITH_TABLES_EDITOR_TABLE_REMOVE_TABLE = {
  sk: 'Odstrániť tabuľku',
  cs: 'Odstranit tabulku',
  en: 'Delete table',
} as const;

export const TIME_FORMAT = {
  sk: 'HH:mm',
  cs: 'HH:mm',
  en: 'HH:mm',
} as const;

export const VALIDATION_TOAST_DESCRIPTION = {
  sk: 'Vo formulári sa nachádzajú chyby!',
  cs: 'Ve formuláři se nacházejí chyby!',
  en: 'There are errors in the form!',
} as const;

export const VALIDATION_TOAST_TITLE = {
  sk: 'Chyba',
  cs: 'Chyba',
  en: 'Error',
} as const;

export const YES = {
  sk: 'áno',
  cs: 'ano',
  en: 'yes',
} as const;
